
import Vue from 'vue';
import VueLottiePlayer from 'vue-lottie-player';
import AtomInput from '@/components/atoms/AtomInput.vue';
import AtomText from '@/components/atoms/AtomText.vue';
import AtomActionText from '@/components/atoms/AtomActionText.vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import MoleculeModal from '@/components/molecules/modal/MoleculeModal.vue';
import { mapActions, mapGetters } from 'vuex';
import { sendSms, updateUser, verifySms } from '@/api/user/userApi';
import { toast, ToastVariants } from '@/components/atoms/AtomToast';

export const PHONE_VALIDATION_MODAL_ID = 'tg-modal--phone-validation';

export default Vue.extend({
  name: 'OrganismPhoneValidation',
  components: {
    VueLottiePlayer,
    AtomActionText,
    AtomInput,
    AtomButton,
    AtomText,
    AtomSvgIcon,
    MoleculeModal,
  },
  props: {
    sticky: {
      type: Boolean,
      default: false,
    },
    showDeletePhone: {
      type: Boolean,
      default: false,
    },
    skipValidation: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    phone: '',
    smsCode: (new Array(5) as string[]).fill(''),
    isOverlayOpened: false,
    showInputs: true,
    ButtonVariant,
    PHONE_VALIDATION_MODAL_ID,
    isLoadingAddPhone: false,
    isLoadingValidatePhone: false,
    showDeleteWarning: false,
  }),
  computed: {
    ...mapGetters('user', [
      'getUserId',
      'firstName',
      'lastName',
      'displayName',
      'gender',
      'unitOfLengthLocale',
      'homePOI',
      'phoneNumber',
      'isPhoneVerified',
      'sms',
      'rideSettings',
      'decodedImage',
    ]),
  },
  methods: {
    ...mapActions('user', ['fetchUser']),
    async resetInputs() {
      this.smsCode = (new Array(5) as string[]).fill('');
      this.showInputs = false;
      await this.$nextTick();
      this.showInputs = true;
      await this.$nextTick();
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      this.$refs.code[0].$el.querySelector('input').focus();
    },
    async resendSms() {
      const { status: smsStatus } = await sendSms(this.phone, this.getUserId);
      if (smsStatus === 201) {
        this.isOverlayOpened = true;
        this.resetInputs();

        toast(
          this.$bvToast,
          this.$t('addPhoneModal.SmsCodeResend').toString(),
          ToastVariants.SUCCESS,
        );
      } else {
        toast(
          this.$bvToast,
          this.$t('addPhoneModal.sendSmsCodeError').toString(),
          ToastVariants.DANGER,
        );
      }
    },
    hideModal($ev?) {
      this.$bvModal.hide(PHONE_VALIDATION_MODAL_ID);
      if (this.skipValidation) {
        this.$emit('skip');
        localStorage.setItem('SKIP_PHONE_VALIDATION', 'true');
      }
      if ($ev) this.$emit('cancel');
    },
    closeOverlayAction() {
      this.isOverlayOpened = false;
      this.resetInputs();
    },
    setSmsCode(code: string, index: number) {
      if (this && code.length <= 1) {
        this.smsCode[index] = code;
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        const nextRef = this.$refs.code[index + 1];
        if (nextRef) {
          nextRef.$el.querySelector('input').focus();
        }
      }
    },
    async verifyPhone() {
      this.isLoadingValidatePhone = true;
      const res = await verifySms(
        {
          contactId: this.sms.contactId,
          verificationCode: this.smsCode.join(''),
        },
        this.getUserId,
      );

      if (res.status === 201) {
        this.fetchUser(this.getUserId);
        this.$bvModal.hide(PHONE_VALIDATION_MODAL_ID);
        this.isOverlayOpened = false;
        this.smsCode = (new Array(5) as string[]).fill('');
        this.$emit('done');
        toast(
          this.$bvToast,
          this.$t('addPhoneModal.SmsCodeVerified').toString(),
          ToastVariants.SUCCESS,
        );
      } else {
        toast(
          this.$bvToast,
          this.$t('addPhoneModal.SmsCodeError').toString(),
          ToastVariants.DANGER,
        );
      }
      this.isLoadingValidatePhone = false;
    },
    async addPhone() {
      this.isLoadingAddPhone = true;
      const { status } = await updateUser({
        userId: this.getUserId,
        firstName: this.firstName,
        lastName: this.lastName,
        displayName: this.displayName,
        unitOfLengthLocale: this.unitOfLengthLocale,
        gender: this.gender,
        rideSettings: {
          maxDetourMinutes: this.rideSettings.maxDetourMinutes,
          minSharedDurationPercentage: this.rideSettings.minSharedDurationPercentage,
          role: this.rideSettings.role,
        },
        sms: {
          detail: this.phone,
          usageTypes: ['ContactVerification', 'Help', 'Unsubscribe', 'RideCreatedUpdatedCancelled'], // TODO: confirm this if this Array contains anything needed
        },
      });
      if (status === 200) {
        this.fetchUser(this.getUserId);
        const { status: smsStatus } = await sendSms(this.phone, this.getUserId);
        if (smsStatus === 201) {
          this.isOverlayOpened = true;
          await this.$nextTick();
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          this.$refs.code[0].$el.querySelector('input').focus();
        }
      } else {
        toast(
          this.$bvToast,
          this.$t('addPhoneModal.phoneNumberError').toString(),
          ToastVariants.DANGER,
        );
      }
      this.isLoadingAddPhone = false;
    },
    async removePhone() {
      this.isLoadingAddPhone = true;
      const { status } = await updateUser({
        userId: this.getUserId,
        firstName: this.firstName,
        lastName: this.lastName,
        displayName: this.displayName,
        unitOfLengthLocale: this.unitOfLengthLocale,
        gender: this.gender,
        rideSettings: {
          maxDetourMinutes: this.rideSettings.maxDetourMinutes,
          minSharedDurationPercentage: this.rideSettings.minSharedDurationPercentage,
          role: this.rideSettings.role,
        },
        sms: null,
      });
      if (status === 200) {
        this.fetchUser(this.getUserId);
      } else {
        toast(
          this.$bvToast,
          this.$t('addPhoneModal.phoneNumberError').toString(),
          ToastVariants.DANGER,
        );
      }
      this.showDeleteWarning = false;
      this.isLoadingAddPhone = false;
    },
  },
  mounted() {
    this.phone = this.phoneNumber;
  },
  watch: {
    phoneNumber(phone) {
      this.phone = phone;
    },
  },
});
